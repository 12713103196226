<template>
  <div class="register-auth-root-container">
    <logo style="margin-top: 70px" />
    <div v-if="currentIndex == 0" class="register-auth-container">
      <div
        v-if="type == 1"
        style="display:flex-direction:column;align-items:center;width:100%;"
      >
        <div
          style="
            font-size: 23px;
            font-weight: 500;
            color: #090909;
            margin-top: 30px;
          "
        >
          第一步(1 of 3)：创建第一个雇主品牌信息
        </div>
        <div
          style="font-size: 16px;color #5A5A5A;margin-top:20px;margin-bottom:50px;"
        >
          您还可以稍后在雇主品牌首页处设置和完善更多的信息😊
        </div>
        <my-input
          ref="formEmployerName"
          v-model="registForm.employer_name"
          placeholder="如XX集团、XX公司等，不需要与您的工商名称一致"
          style="width: 90%; margin: 0 auto"
          title="雇主品牌名称"
          size="large"
          :validate="employNameValidate"
        ></my-input>
        <div
          style="
            font-size: 15px;
            font-weight: bold;
            color: #0f0f0f;
            align-self: flex-start;
            margin-top: 30px;
            width: 90%;
            margin: 0 auto;
          "
        >
          为雇主品牌添加一个Logo（也可以稍后进行）
        </div>
        <my-single-image
          ref="formEmployerLogo"
          class="register-auth-form-image"
          style="margin-left: 20px"
          :before-upload="handleLogoBeforeUpload"
          :img-url="registForm.employer_img_url"
        ></my-single-image>
        <my-input
          ref="formEmployerPhone"
          v-model="registForm.employer_phone"
          placeholder="雇主手机号"
          style="margin-top: 15px; width: 90%; margin: 0 auto"
          title="雇主手机号"
          size="large"
          :validate="registPhoneValidate"
        ></my-input>
        <Button
          type="primary"
          size="large"
          style="width: 90%; margin-top: 50px; margin-left: 20px"
          @click="createEmployerNext"
          >下一步</Button
        >
      </div>
      <div
        v-else
        style="display:flex-direction:column;align-items:center;width:90%;"
      >
        <div
          style="
            font-size: 23px;
            font-weight: 500;
            color: #090909;
            margin-top: 30px;
          "
        >
          第一步(1 of 3)：确认被邀请加入的雇主品牌
        </div>
        <div
          style="
            font-size: 15px;
            font-weight: 500;
            color: #111111;
            margin-top: 100px;
          "
        >
          被邀请加入的雇主品牌名称
        </div>
        <div
          style="
            width: 100%;
            height: 60px;
            background-color: #f0f0f0;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 15px;
            color: #5b5b5b;
            font-size: 18px;
            font-weight: bold;
            margin-top: 20px;
          "
        >
          {{ employerForm.name }}
        </div>
        <Button
          type="primary"
          size="large"
          style="width: 100%; margin-top: 50px"
          @click="inviteNext"
          >下一步</Button
        >
      </div>
    </div>
    <div v-if="currentIndex == 1" class="register-auth-container">
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #090909;
          margin-top: 30px;
        "
      >
        第二步(2 of 3)：确认被邀请加入的雇主品牌
      </div>
      <my-input
        ref="formUserName"
        v-model="registForm.name"
        placeholder="如张三"
        title="您的全名"
        size="large"
        style="width: 100%; margin-top: 30px"
        :validate="userNameValidate"
      ></my-input>
      <div
        style="
          align-self: flex-start;
          font-size: 15px;
          font-weight: bold;
          color: #111111;
        "
      >
        头像(可以稍后上传)
      </div>
      <my-single-image
        class="register-auth-form-image"
        ref="formImage"
        :before-upload="handleImgBeforeUpload"
        :img-url="registForm.img_url"
      ></my-single-image>
      <my-input
        v-if="!(h == 1)"
        ref="formPassword"
        v-model="registForm.pwd"
        placeholder="不低于8位，至少包含一个大写字母、小写字母以及数字"
        title="密码"
        size="large"
        type="password"
        style="width: 100%; margin-top: 30px"
        :validate="passwordValidate"
      ></my-input>
      <my-input
        ref="formPhone"
        v-model="registForm.phone"
        title="常用手机号"
        size="large"
        style="width: 100%; margin-top: 30px"
        :validate="phoneValidate"
      ></my-input>
      <Button
        type="primary"
        size="large"
        style="width: 100%; margin-top: 25px"
        @click="doRegist"
        >下一步</Button
      >
    </div>
    <div
      v-if="currentIndex == 2"
      class="register-auth-container"
      style="max-width: 50%; text-align: center"
    >
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #090909;
          margin-top: 30px;
        "
      >
        第三步(3 of 3)：开始使用游用工
      </div>
      <div style="color: #666666; font-size: 23px; margin-top: 100px">
        欢迎加入游用工。您可以通过我们的平台管理您的外包项目，雇用自由职业者，管理项目，并为满意的工作成果支付。
      </div>
      <Button
        type="primary"
        size="large"
        style="width: 30%; margin-top: 100px"
        @click="login"
        >去登录</Button
      >
    </div>
  </div>
</template>

<script>
import Logo from "../../components/logo";
import * as oss from "../../utils/ossHelper";
import MyInput from "../../components/Form/MyInput";
import MySingleImage from "../../components/Form/MySingleImage";
import { mapActions } from "vuex";
export default {
  components: {
    logo: Logo,
    "my-input": MyInput,
    "my-single-image": MySingleImage,
  },
  data() {
    return {
      h: 0,
      type: 1,
      guid: "",
      currentIndex: 0,
      username: "",
      password: "",
      img_url: "",
      logo_img_url: "",
      phone: "",
      accessories: [],

      registForm: {
        guid: "",
        employer_name: "",
        employer_phone: "",
        employer_img_url: "",
        name: "",
        img_url: "",
        pwd: "",
        phone: "",
      },
      employerForm: {
        guid: "",
        name: "",
        img_url: "",
        pwd: "",
        phone: "",
      },
    };
  },
  mounted() {
    this.h = this.getParam("h");
    this.type = this.getParam("t");
    this.registForm.guid = this.getParam("g");
    this.employerForm.guid = this.getParam("g");

    if (this.type == 2) {
      this.getEmployerAction({
        guid: this.employerForm.guid,
      })
        .then((res) => {
          this.employerForm.name = res.employer_name;
          this.registForm.employer_name = res.employer_name;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    }
  },
  methods: {
    ...mapActions({
      logoutAction: "logout",
      registAction: "regist",
      getEmployerAction: "getEmployer",
      getUserInfoAction: "getUserInfo",
      bindEmployerAction: "bindEmployer",
    }),
    getParam(name) {
      var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
      if (reg.test(location.href))
        return unescape(RegExp.$2.replace(/\+/g, " "));
      return "";
    },
    employNameValidate() {
      return new Promise((resolve, reject) => {
        if (this.registForm.employer_name == "") {
          reject("请填写雇主品牌名称");
          return;
        }
        resolve();
      });
    },
    userNameValidate() {
      return new Promise((resolve, reject) => {
        if (this.registForm.name == "") {
          reject("请填写您的姓名");
          return;
        }
        resolve();
      });
    },
    passwordValidate() {
      return new Promise((resolve, reject) => {
        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g.test(
            this.registForm.pwd
          )
        ) {
          reject("密码长度为8到20位,必须包含字母和数字，字母区分大小写");
          return;
        }
        resolve();
      });
    },
    phoneValidate() {
      return new Promise((resolve, reject) => {
        if (!/^1[3456789]\d{9}$/.test(this.registForm.phone)) {
          reject("请填写正确的手机号");
          return;
        }
        resolve();
      });
    },
    registPhoneValidate() {
      return new Promise((resolve, reject) => {
        if (!/^1[3456789]\d{9}$/.test(this.registForm.employer_phone)) {
          reject("请填写正确的手机号");
          return;
        }
        resolve();
      });
    },
    // employerLogoValidate() {
    //   return new Promise((resolve, reject) => {
    //     if (this.registForm.employer_img_url == "") {
    //       reject("请上传品牌Logo");
    //       return;
    //     }
    //     resolve();
    //   });
    // },
    inviteNext() {
      let all = [];
      if (this.$refs.formEmployerPhone) {
        all.push(this.$refs.formEmployerPhone.doValidate());
      }
      Promise.all(all)
        .then(() => {
          this.currentIndex += 1;
          if (this.h == 1) {
            this.getUserInfoAction({
              guid: this.registForm.guid,
            }).then((res) => {
              this.employerForm.name = res.name;
              // this.employerForm.phone = res.phone;
              this.employerForm.img_url = res.img_url;
              this.registForm.name = res.name;
              this.registForm.phone = res.phone;
              this.registForm.img_url = res.img_url;
            });
          }
        })
        .catch((error) => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    handleImgBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "avatar").then((result) => {
        this.registForm.img_url = result.fullPath;
        this.employerForm.img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    handleLogoBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "employer").then((result) => {
        this.registForm.employer_img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    createEmployerNext() {
      Promise.all([
        this.$refs.formEmployerName.doValidate(),
        // this.$refs.formEmployerLogo.doValidate(),
      ])
        .then(() => {
          this.currentIndex += 1;
        })
        .catch((error) => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    doRegist() {
      if (this.h == 1) {
        // 有信息,调bindEmployer
        Promise.all([
          this.$refs.formUserName.doValidate(),
          this.$refs.formImage.doValidate(),
          // this.$refs.formPassword.doValidate(),
          this.$refs.formPhone.doValidate(),
        ])
          .then(() => {
            this.bindEmployerAction(this.employerForm)
              .then((res) => {
                this.currentIndex += 1;
              })
              .catch((error) => {
                this.$Message.error(error);
              });
          })
          .catch((error) => {
            this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
          });
      } else {
        // 没有信息,调regist
        Promise.all([
          this.$refs.formUserName.doValidate(),
          this.$refs.formImage.doValidate(),
          this.$refs.formPassword.doValidate(),
          this.$refs.formPhone.doValidate(),
        ])
          .then(() => {
            this.registAction(this.registForm)
              .then((res) => {
                this.currentIndex += 1;
              })
              .catch((error) => {
                this.$Message.error(error);
              });
          })
          .catch((error) => {
            this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
          });
      }
    },
    login() {
      this.logoutAction();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.register-auth-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register-auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40%;
  margin: 0 auto;
}
.register-auth-form-image {
  width: 100px;
  height: 118px;
  margin-top: 15px;
  align-self: flex-start;
}
</style>